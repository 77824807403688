import { de, en, fr } from 'vuetify/locale'

export default defineI18nConfig(() => ({
  legacy: false,
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  fallbackLocale: 'en',
  messages: {
    en: { $vuetify: en },
    fr: { $vuetify: fr },
    de: { $vuetify: de },
  },
}))
